<template>
  <b-card-code>
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent="submitForm">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label>Voucher Type</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Voucher Type" rules="required">
                <v-select v-model="vouchertype" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name" :options="[
                    'Sales Cancel',
                    'Bulk Land Sales Cancel',
                    'Commission Payment',
                    'Other',
                  ]" @input="handleType($event)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Payment Date </label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Payment Date" rules="required">
                <flat-pickr v-model="paymentdate" class="form-control" :config="{
                  dateFormat: 'd/m/Y',
                  defaultDate: new Date(),
                  maxDate: new Date(),
                }" style="background-color: transparent" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Transaction type</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Transaction Type" rules="required">
                <v-select v-model="transactiontype" placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="[
                    'None',
                    'Cash',
                    'Cheque',
                    'DD',
                    'e-Fund Transfer',
                    'NEFT',
                    'IMPS',
                    'RTGS',
                    'IFTO',
                    'Other',
                  ]" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Company Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="company" rules="required">
                <v-select v-model="companyname" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name" :options="companyOption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4" v-if="vouchertype == 'Sales Cancel'">
            <b-form-group>
              <label>Project</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="company" rules="required">
                <v-select v-model="project" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="projectname" :options="projectOptions" @input="handleProject($event)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="vouchertype == 'Sales Cancel'">
            <b-form-group>
              <label>Select Plot</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="company" rules="required">
                <v-select v-model="plot" placeholder="None" label="plotno" :options="plotOptions"
                  @input="handlePlot($event)" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>Receiver Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Receiver" rules="required">
                <v-select v-model="suppliername" placeholder="None" :disabled="vouchertype == 'Sales Cancel'"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="supplierOption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>Bank/Cash</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                name="Bank/Cash"
                rules="required"
              >
                <v-select
                  v-model="bankcash"
                  placeholder="None"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="['Bank', 'Cash', 'Other']"
                  @input="BankOrCash($event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label>Ledger Name</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Ledger" rules="required">
                <v-select v-model="ledgername" placeholder="None" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name" :options="ledgernameOption" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="bankcash == 'Bank'">
            <b-form-group>
              <label>Account No</label>
              <b-form-input type="number" v-model="accountno" maxlength="30" placeholder="Enter Account No" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="bankcash == 'Bank'">
            <b-form-group>
              <label>IFSC Code</label>
              <b-form-input v-model="ifsccode" maxlength="11" @input="validateIFSCCodeClick($event)"
                placeholder="Enter IFSC Code" />
              <small v-if="ifsccodeValidate == false" class="text-danger">Enter Valid IFSC Code</small>
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="bankcash == 'Bank'">
            <b-form-group>
              <label>Bank Name</label>
              <b-form-input v-model="bankname" placeholder="Enter Bank Name" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="bankcash == 'Bank'">
            <b-form-group>
              <label>Branch Name</label>
              <b-form-input v-model="branchname" placeholder="Enter Branch Name" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="bankcash == 'Bank'">
            <b-form-group>
              <label>Cheque No</label>
              <b-form-input v-model="chequeno" placeholder="Enter Cheque No" />
            </b-form-group>
          </b-col>
          <b-col md="4" v-if="bankcash == 'Bank'">
            <b-form-group>
              <label>Cheque Date </label>
              <flat-pickr v-model="chequedate" class="form-control" :config="{
                dateFormat: 'd/m/Y',
                defaultDate: new Date(),
                maxDate: new Date(),
              }" style="background-color: transparent" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Remarks</label>
              <b-form-input v-model="remarks" placeholder="Enter Remarks" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Total Amount</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Total Amount" rules="required">
                <b-form-input v-model="totalamount" placeholder="Enter Total Amount" type="number" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment1</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" name="Attachment 1" rules="required">
                <b-form-file v-model="documents.attachment1" placeholder="Select Attachment 1"
                  drop-placeholder="Drop file here..." accept="image/*"
                  @input="handleFileChange($event, 'attachment1', 'receiptreport')" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <attachment :data="documents.attachment1" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment2</label>
              <b-form-file v-model="documents.attachment2" placeholder="Select Attachment 2"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFileChange($event, 'attachment2', 'receiptreport')" />
            </b-form-group>
            <attachment :data="documents.attachment2" />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label>Attachment3</label>
              <b-form-file v-model="documents.attachment3" placeholder="Select Attachment 3"
                drop-placeholder="Drop file here..." accept="image/*"
                @input="handleFileChange($event, 'attachment3', 'receiptreport')" />
            </b-form-group>
            <attachment :data="documents.attachment3" />
          </b-col>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="submitForm" :disabled="flag || submitDisable">
              Submit
            </b-button>
            <b-button v-if="$route.query.id" :disabled="this.approve == 1" class="ml-1" variant="success"
              @click="handleApprove">Approve
            </b-button>
            <b-button v-if="$route.query.id" :disabled="this.approve == 0" class="ml-1" variant="danger"
              @click="handleReject">Reject
            </b-button>
            <b-button class="ml-1" variant="primary" @click="onClickBack">Back </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code";
import PinchScrollZoom from "@coddicat/vue-pinch-scroll-zoom";
import {
  BFormInput,
  BFormFile,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
  BTable,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  min_value,
} from "@validations";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import axios from "@/components/axios";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import Attachment from "../../../components/Attechment.vue";

export default {
  components: {
    flatPickr,
    PinchScrollZoom,
    Datepicker,
    BFormFile,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    BFormDatepicker,
    BFormTextarea,
    BImgLazy,
    BTable,
    Attachment,
  },
  data() {
    return {
      documents: {
        attachment1: "1",
        attachment2: "",
        attachment3: "",
      },
      vouchertype: "",
      paymentdate: moment(new Date()).format("DD/MM/yyyy"),
      transactiontype: "",
      companyname: "",
      suppliername: "",
      bankcash: "",
      ledgername: "",
      accountno: "",
      ifsccode: "",
      ifsccodeValidate: true,
      bankname: "",
      branchname: "",
      chequeno: "",
      utrno: "",
      chequedate: moment(new Date()).format("DD/MM/yyyy"),
      remarks: "",
      totalamount: "",

      approve: "5",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      ifEdit: false,
      companyOption: [],
      supplierOption: [],
      ledgernameOption: [],
      id: "",
      flag: false,
      submitDisable: false,
      projectOptions: [],
      plotOptions: [],
      plot: null,
      project: null,
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Payment") {
          if (this.$route.params.id) {
            if (item.edit !== 1) {
              this.$router.push("/accounting/payment");
            }
          } else if (item.add !== 1) {
            this.$router.push("/accounting/payment");
          }
        }
      });
    }
    this.init();
    this.ifEdit = !!this.$route.params.id;
    this.ifEdit && this.getEditValue();
  },
  methods: {
    handleType(e) {
      if (!e) {
        this.suppliername = null;
        this.project = null;
        this.plot = null;
        this.plotOptions = [];
      }
    },
    async handleProject(e) {
      if (e) {
        await axios({
          method: "GET",
          url: `${this.baseApi}/getCancelPlotById/${e.id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
        })
          .then((json) => {
            this.plotOptions = json.data;
          })
          .catch((error) => console.log(error, "error"));
      } else {
        this.plot = null;
        this.suppliername = null;
      }
    },
    handlePlot(e) {
      if (!e) {
        this.suppliername = null;
        return;
      }
      this.suppliername = e.customer;
    },
    handleApprove() {
      this.approve = 1;
    },
    handleReject() {
      this.approve = 0;
    },
    async getEditValue() {
      await axios({
        method: "GET",
        url: `${this.baseApi}/getpaymentById/${this.$route.params.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.getEditItems(json.data.data);
        })
        .catch((error) => console.log(error, "error"));
    },
    getEditItems(item) {
      item.map((item) => {
        this.vouchertype = item.vouchertype;
        this.paymentdate = item.paymentdate == "00/00/0000" ? null : item.paymentdate;
        this.transactiontype = item.transactiontype;
        this.companyname = item.company;
        this.suppliername = item.supplier;
        this.bankcash = item.bankcash;
        this.ledgername = item.ledger;
        this.accountno = item.accountno;
        this.ifsccode = item.ifsccode;
        this.bankname = item.bankname;
        this.branchname = item.branchname;
        this.chequeno = item.chequeno;
        this.utrno = item.utrno;
        this.chequedate =
          item.chequedate == "00/00/0000"
            ? null
            : moment(item.chequedate).format("DD/MM/YYYY");
        this.project = item.project;
        this.plot = item.plot;
        this.remarks = item.remarks;
        this.totalamount = item.totalamount;
        this.approve = item.approve;
        this.documents.attachment1 = item.attachment1;
        this.documents.attachment2 = item.attachment2;
        this.documents.attachment3 = item.attachment3;
      });
    },
    validateIFSCCodeClick(e) {
      const regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
      this.ifsccode = this.ifsccode.toUpperCase();
      if (regex.test(this.ifsccode) == true) {
        this.ifsccodeValidate = true;
      } else {
        this.ifsccodeValidate = false;
      }
    },
    submitForm(e) {
      this.$refs.simpleRules.validate().then(async (success) => {
        let data;
        if (success) {
          data = {
            vouchertype: this.vouchertype,
            paymentdate: moment(this.paymentdate, "DD/MM/yyyy").format("YYYY-MM-DD"),
            transactiontype: this.transactiontype,
            companyname: this.companyname.id,
            suppliername: this.suppliername.id,
            project: this.project ? this.project.id : "",
            plot: this.plot ? this.plot.id : "",
            bankcash: this.bankcash,
            ledgername: this.ledgername.id,
            accountno: this.accountno,
            ifsccode: this.ifsccode,
            bankname: this.bankname,
            branchname: this.branchname,
            chequeno: this.chequeno,
            utrno: this.utrno,
            chequedate: moment(this.chequedate, "DD/MM/yyyy").format("YYYY-MM-DD"),
            remarks: this.remarks,
            approve: this.approve,
            totalamount: this.totalamount,
            attachment1: this.documents.attachment1,
            attachment2: this.documents.attachment2,
            attachment3: this.documents.attachment3,
          };
        }

        // var ifEdit = this.$route.params.id ? true : false;
        if (success == false) {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Plese Fill the Required Field",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
        if (success) {
          this.submitDisable = true;
          const accessToken = localStorage.getItem("accessToken");
          const baseApi = process.env.VUE_APP_APIENDPOINT;
          await axios({
            method: `${this.ifEdit ? "put" : "post"}`,
            url: this.ifEdit
              ? `${baseApi}/payment/${this.$route.params.id}`
              : `${baseApi}/payment`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            data: JSON.stringify(data),
          })
            .then((json) => {
              this.submitDisable = false;
              if (json.data.status == 200 || json.data) {
                this.$swal({
                  title: "Submited",
                  text: json.data.message
                    ? `${json.data.message}`
                    : "Update SuccessFully",
                  icon: "success",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
              this.$router.push("/accounting/payment");
            })
            .catch((error) => {
              this.submitDisable = false;
              this.$swal({
                title: "Error!",
                text: `${error}`,
                icon: "error",
                timer: 5000,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    onClickBack() {
      this.$router.push("/accounting/payment");
    },
    async init() {
      this.getCompany();
      this.getProject();
      this.getSupplier();
    },
    async getProject() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getCompany() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getCompany`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.companyOption = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getSupplier() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getLedger`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.supplierOption = this.$store.state.app.all_users;
          this.ledgernameOption = this.$store.state.app.all_users;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleFileChange(e, name, type) {
      this.flag = true;
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.documents[name] =
            process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
          this.flag = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    async BankOrCash(e) {
      this.accountno = "";
      this.ifsccode = "";
      this.bankname = "";
      this.branchname = "";
      this.chequeno = "";
      this.chequedate = "";
      this.utrno = "";

      this.ledgername = "";
      this.bankcash = e;
      if (e == "Bank") {
        this.ledgernameOption = [];
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/bankledger`,
        };
        await axios(requestOptions)
          .then((response) => {
            this.ledgernameOption = response.data.data;
          })
          .catch((error) => console.log(error, "error"));
      } else if (e == "Cash") {
        this.ledgernameOption = [];
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/cash`,
        };
        await axios(requestOptions)
          .then((response) => {
            this.ledgernameOption = response.data.data;
          })
          .catch((error) => console.log(error, "error"));
      } else {
        this.ledgernameOption = [];
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: `${this.baseApi}/other`,
        };
        await axios(requestOptions)
          .then((response) => {
            this.ledgernameOption = response.data.data;
          })
          .catch((error) => console.log(error, "error"));
      }
    },
  },
};
</script>
